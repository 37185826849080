import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "develop-with-helsinki"
    }}>{`Develop with Helsinki`}</h1>
    <hr></hr>
    <p>{` `}<strong parentName="p"><em parentName="strong">{`This website is no longer updated. Up-to-date documentation can be found in our `}<a parentName="em" {...{
            "href": "https://helsinkisolutionoffice.atlassian.net/wiki/spaces/OS/"
          }}>{`Confluence wiki`}</a>{`.`}</em></strong></p>
    <p>{`This site covers the practical details for developing open source code for the City of Helsinki. The target audience is software development consultants working for Helsinki codebases, Helsinki software development staff, and people offering contributions to Helsinki software. The documentation is produced jointly by the software developers of the Executive Office and the divisions of the city.`}</p>
    <h3 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h3>
    <p>{`Topics such as `}<a parentName="p" {...{
        "href": "/coding-standards"
      }}>{`coding standards`}</a>{`, `}<a parentName="p" {...{
        "href": "/technology-choices"
      }}>{`technology choices`}</a>{` and `}<a parentName="p" {...{
        "href": "/scrum-project-management"
      }}>{`agile practices`}</a>{` are covered here. Not all practices stated here will apply to all projects within the city but these are intended as general outlines of best practices.`}</p>
    <h3 {...{
      "id": "api-documentation"
    }}>{`API documentation`}</h3>
    <p>{`Here you will find documentation for several City of Helsinki open APIs that cover `}<a parentName="p" {...{
        "href": "apis/servicemap"
      }}>{`services`}</a>{`, `}<a parentName="p" {...{
        "href": "apis/linkedevents"
      }}>{`events`}</a>{`, `}<a parentName="p" {...{
        "href": "apis/openahjo"
      }}>{`decision-making`}</a>{`, `}<a parentName="p" {...{
        "href": "apis/respa"
      }}>{`bookable rooms and equipment`}</a>{` and `}<a parentName="p" {...{
        "href": "apis/open311"
      }}>{`reporting issues and giving feedback`}</a>{`.`}</p>
    <h3 {...{
      "id": "resources"
    }}>{`Resources`}</h3>
    <p>{`Useful resources for developers include `}<a parentName="p" {...{
        "href": "/django-libraries"
      }}>{`Helsinki Django libraries`}</a>{`, `}<a parentName="p" {...{
        "href": "/maps"
      }}>{`Helsinki map layers`}</a>{`, `}<a parentName="p" {...{
        "href": "https://city-of-helsinki.github.io/helsinki-design-system/"
      }}>{`Helsinki Design System`}</a>{`, `}<a parentName="p" {...{
        "href": "https://digi.hel.fi/digipalveluopas/periaatteet/"
      }}>{`Helsinki guidelines for user driver digital service development`}</a>{` (in Finnish) and `}<a parentName="p" {...{
        "href": "https://kehmet.hel.fi/"
      }}>{`Helsinki best practices for IT development`}</a>{` (in Finnish).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      